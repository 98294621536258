<template>
  <div class="tooltip">
      <p>{{municipio}}</p>
      <p>{{descripcion}}</p>
  </div>
</template>

<script>
export default {
    props: {
        municipio:{
            type: String,
            default:'Municipio'
        },
        descripcion:{
            type: Number,
            default:0
        }
    }
}
</script>

<style scoped>
    .tooltip{
        width:300px;
        height:200px;
        font-size:12px;
        background-color: #ddd;
        position:absolute;
        z-index:10;
    }
</style>


