<template>
  <div class="componente-filtro">
      <h3>{{titulo}}</h3>
      <select @change="checkChange">
        <option :value="option.value" v-for="(option, index) in data" :key="index" :disabled="deshabilitar" class="option-filter">
            {{option.nombre}}
        </option>
      </select>
  </div>
</template>

<script>
export default {
    props:{
        titulo:{
            type: String,
            default:'Deshabilitado'
        },
        data:{
            type:Array
        },
        checkChange:{
            type: Function
        },
        deshabilitar: {
            type: Boolean,
            default: false
        }
    },
    setup(props){

        

       return {
          
       }
    }

}
</script>

<style scoped>
    .componente-filtro{
        width:80%;
        margin:10px auto;
        padding:20px 10px;
        border-bottom:1px solid black;
    }
    .componente-filtro h3{
        font-size:14px;

    }
    .componente-filtro p{
        font-size:12px;
        margin:0;
    }
    select{
        width:90%;
        margin:0 auto;
    }
</style>