<template>
 <div class="moduloJuridico">
        <div class="contenedorJuridico">
            <div class="juridicoTitulo">
                <h1>Antecedentes | Marco Jurídico</h1>
            </div>
        </div>
        <div class="contenedorMarcoJuridico">
            <div class="marcoJuridicoTitulo">
                <h1>Marco Jurídico</h1>
            </div>
            <div class="marcoJuridicoContenido">
                <div class="juridicoAcuerdos">
                    <h3>Acuerdos y Tratados Internacionales</h3>
                        <ul>
                            <a href="https://www.unwomen.org/es/how-we-work/intergovernmental-support/world-conferences-on-women#mexico" target="_blank"><li>1ª Conferencia Mundial Sobre la Mujer, CDMX, 1975</li></a>
                            <a href="http://habitat1.org/ " target="_blank"><li>Hábitat I, Vancouver, 1976</li></a>
                            <a href="https://www.cepal.org/mujer/publicaciones/xml/3/29903/InformePrimera.pdf " target="_blank"><li>Plan de Acción Regional de la Habana, 1977.</li></a>
                            <a href=" https://mexico.unwomen.org/es/digiteca/publicaciones/2011/12/cedaw"><li>Convención sobre la Eliminación de todas las Formas de Discriminación contra las Mujeres (CEDAW por sus siglas en inglés), 1979 y su Protocolo Facultativo, 2000</li></a>
                            <a href="https://www.unwomen.org/es/how-we-work/intergovernmental-support/world-conferences-on-women#copenhagen" target="_blank"><li>2ª Conferencia Mundial Sobre la Mujer, Copenhague, 1980.</li></a>
                            <a href="https://www.unwomen.org/es/how-we-work/intergovernmental-support/world-conferences-on-women#nairobi" target="_blank"><li>3ª Conferencia Mundial Sobre la Mujer, Nairobi, 1985</li></a>
                            <a href="https://www.oas.org/dil/esp/convencion_belem_do_para.pdf" target="_blank"><li>Programa de Acción Regional Mar de Plata, Convención Interamericana para Prevenir, Sancionar y Erradicar la Violencia Contra la Mujer, "Convención De Belém Pará" y su Estatuto de Mecanismo de Seguimiento, 1994</li></a>
                            <a href="https://www.un.org/en/development/desa/population/publications/ICPD_programme_of_action_es.pdf" target="_blank"><li>Programa de Acción de la Conferencia Internacional sobre la Población y el Desarrollo El Cairo, 1994.</li></a>
                            <a href="https://www.unwomen.org/-/media/headquarters/attachments/sections/csw/bpa_s_final_web.pdf?la=es&vs=755" target="_blank"><li>3ª Conferencia Mundial Sobre la Mujer, Beijing, 1995.</li></a>
                            <a href="https://www.unwomen.org/es/how-we-work/intergovernmental-support/world-conferences-on-women" target="_blank"><li>Declaración y Plataforma de Acción de Beijing, la Cuarta Conferencia Mundial sobre la Mujer, 1995.</li></a>
                            <a href="http://habitat2.org/" target="_blank"><li>Hábitat II, Estambul, 1996.</li></a>
                            <a href="https://www.cepal.org/sites/default/files/events/files/ddr8econsenso_de_santiago_0.pdf" target="_blank"><li>Consenso de Santiago, 1997</li></a>
                            <a href=" https://www.cepal.org/cgi-bin/getProd.asp?xml=/mujer/noticias/noticias/4/5144/P5144.xml&xsl=/mujer/tpl/p1f.xsl&base=/tpl/imprimir.xsl" target="_blank"><li>Consenso de Lima, 2000.</li></a>
                            <a href="https://undocs.org/es/S/RES/1325%20%282000%29" target="_blank"><li>Resolución 1325, 2000</li></a>
                            <a href="https://www.oas.org/es/mesecvi/nosotros.asp" target="_blank"><li>Consenso México, 2004. MESECVI.</li></a>
                            <a href="https://www.uv.mx/uge/files/2014/05/Consenso-de-Quito.pdf" target="_blank"><li>Consenso de Quito, 2007.</li></a>
                            <a href="https://www.cepal.org/mujer/noticias/paginas/5/40235/ConsensoBrasilia_ESP.pdf" target="_blank"><li>Consenso de Brasilia, 2010.</li></a>
                            <a href="https://www.cepal.org/12conferenciamujer/noticias/paginas/6/49916/PLE_Consenso_de_Santo_Domingo.pdf" target="_blank"><li>Consenso de Santo Domingo, 2013.</li></a>
                            <a href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/" target="_blank"><li>Objetivos del Desarrollo Sostenible, Agenda 2030, 2015.</li></a>
                            <a href="http://habitat3.org/" target="_blank"><li>Hábitat III, Quito, 2016.</li></a>
                            <a href="https://www.cepal.org/sites/default/files/document/files/estrategia_de_montevideo_para_la_implementacion_de_la_agenda_regional_de_genero_en_el_marco_del_desarrollo_sostenible_hacia_2030.pdf " target="_blank"><li>Estrategia de Montevideo, 2016.</li></a>
                    </ul>
                </div>
                <div class="juridicoLeyes">
                    <h3>Leyes</h3>
                    <ul>
                        <a href="http://www.diputados.gob.mx/LeyesBiblio/ref/lgamvlv.htm" target="_blank"><li>Ley general de acceso de las mujeres a una vida libre de violencia</li></a>
                        <a href="http://marcojuridico.morelos.gob.mx/archivos/leyes/pdf/LMUJERVVEM.pdf" target="_blank"><li>Ley de acceso a las mujeres a una vida libre de violencia para el estado de Morelos</li></a>
                        <a href="http://imem.morelos.gob.mx/sites/imem.morelos.gob.mx/files/12%20LREPARAEM.pdf" target="_blank"><li>ley de atención y reparación a víctimas del delito y de violacionesa los derechos humanos para el estado de Morelos</li></a>
                        <a href="http://marcojuridico.morelos.gob.mx/archivos/decretos_ejecutivo/pdf/DVIOLMUJMO.pdf" target="_blank"><li>Decreto por el que se integra el sistema estatal para prevenir, atender, sancionar y erradicar la violencia contra las mujeres</li></a>
                        <a href="https://www.gob.mx/cms/uploads/attachment/file/112978/Reglamento_de_la_Ley_General_de_Acceso_de_las_Mujeres_a_una_Vida_Libre_de_Violencia.pdf" target="_blank"><li>Reglamento de la ley general de acceso de las mujeres a una vida libre de violencia</li></a>
                        <a href="http://marcojuridico.morelos.gob.mx/archivos/codigos/pdf/CPENALEM.pdf" target="_blank"><li>Código Penal del Estado de Morelos</li></a>
                        
                    </ul>
                </div>
                <div class="juridicoReglamentos">
                    <h3>Acuerdos</h3>
                    <ul>
                        <a href="http://marcojuridico.morelos.gob.mx/archivos/acuerdos_estatales/pdf/ACU072020PROTOCOLOFEMINICIDIO.pdf" target="_blank"><li>Acuerdo 07/2020 por el que se emite el protocolo de actuación con perspectiva de género para la investigación del Delito de Feminicidio por la Fiscalía General del Estado de Morelos</li></a>
                        <a href="http://marcojuridico.morelos.gob.mx/archivos/acuerdos_estatales/pdf/ACU092019FISCAL.pdf" target="_blank"><li>Acuerdo 09/2019 del Fiscal General del Estado de Morelos, por el que se crea la fiscalía especializada para la investigación y Persecución del Delito de Feminicidio</li></a>
                    </ul>
                </div>
                <div class="juridicoReglamentos">
                    <h3>Sentencias</h3>
                    <ul>
                        <a href="https://www.corteidh.or.cr/docs/casos/articulos/seriec_205_esp.pdf" target="_blank"><li>Caso González y Otras (Campo Algodonero) Vs. México</li></a>
                        <a href="http://repositoriomorelos.mx/sites/default/files/Ejecutivo_Auxiliar/IMEM/2018/Articulo51/LTAIPEM51_FXLIV_A_Informacion_de_interes_publico/2020Julio/SENTENCIA_CASO_MARIANA_LIMA.pdf" target="_blank"><li>Caso Mariana Lima</li></a>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
   @media only screen and (max-width:600px){
        .moduloJuridico{
        width:100%;
        }
        .contenedorJuridico{
            width:100%;
            height:120px;
            background-color: #541279;
        }
        .juridicoTitulo{
            width:100%;
            margin:0 auto;
            height:120px;
            display:flex; align-items: center; justify-content: center;
        }
        .juridicoTitulo h1{
            font-family: 'Montserrat', sans-serif; font-size:14px; font-weight: bold;
            color:#fff;
            text-transform:uppercase;
        }
        .contenedorMarcoJuridico{
            width:100%;
        }
        .marcoJuridicoTitulo{
            width:80%;
            margin:20px auto;
        }
        .marcoJuridicoTitulo h1{
            font-family:'Montserrat',sans-serif;
            font-size:22px; font-weight: bold;
        }
        .marcoJuridicoContenido{
            width:80%;
            margin:10px auto;
        }
        .juridicoAcuerdos{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoAcuerdos h3{
            font-size: 14px;
            font-weight: bold;
        }
        .juridicoAcuerdos ul{
            padding:0;
            margin-left:10px;
        }
        .juridicoAcuerdos a{
            color: black;
        }
        .juridicoAcuerdos a:hover{
            text-decoration:underline;
        }
        .juridicoAcuerdos li {
            font-size:10px;
            text-transform: uppercase;
        }
        .juridicoLeyes{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoLeyes h3{
            font-size:14px;
            font-weight: bold;
        }
        .juridicoLeyes ul{
            padding:0;
            margin-left:10px;
        }
        .juridicoLeyes li{
            font-size:10px;
            text-transform:uppercase;
        }
        .juridicoLeyes a{
            color: black;
        }
        .juridicoLeyes a:hover{
            text-decoration:underline;
        }
        .juridicoReglamentos{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoReglamentos h3{
            font-size:14px;
            font-weight: bold;
        }
        .juridicoReglamentos ul{
            padding:0;
            margin-left:10px;
        }
        .juridicoReglamentos li{
            font-size:10px;
            text-transform:uppercase;
        }
        .juridicoReglamentos a{
            color:black;
        }
        .juridicoReglamento a:hover{
            text-decoration:underline;
        }
 
    }

    @media only screen and (min-width:600px) and (max-width:1024px){
         .moduloJuridico{
        width:100%;
        }
        .contenedorJuridico{
            width:100%;
            height:120px;
            background-color: #541279;
        }
        .juridicoTitulo{
            width:100%;
            margin:0 auto;
            height:120px;
            display:flex; align-items: center; justify-content: center;
        }
        .juridicoTitulo h1{
            font-family: 'Montserrat', sans-serif; font-size:24px; font-weight: bold;
            color:#fff;
            text-transform:uppercase;
        }
        .contenedorMarcoJuridico{
            width:100%;
        }
        .marcoJuridicoTitulo{
            width:70%;
            margin:30px auto;
        }
        .marcoJuridicoTitulo h1{
            font-family:'Montserrat',sans-serif;
            font-size:22px; font-weight: bold;
        }
        .marcoJuridicoContenido{
            width:70%;
            margin:10px auto;
        }
        .juridicoAcuerdos{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoAcuerdos h3{
            font-size: 14px;
            font-weight: bold;
        }
        .juridicoAcuerdos ul{
            padding:0;
            margin-left:10px;
        }
        .juridicoAcuerdos a{
            color: black;
        }
        .juridicoAcuerdos a:hover{
            text-decoration:underline;
        }
        .juridicoAcuerdos li {
            font-size:10px;
            text-transform:uppercase;
        }
        .juridicoLeyes{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoLeyes h3{
            font-size:14px;
            font-weight: bold;
        }
        .juridicoLeyes ul{
            padding:0;
            margin-left:10px;
        }
        .juridicoLeyes li{
            font-size:10px;
            text-transform:uppercase;
        }
        .juridicoLeyes a{
            color: black;
        }
        .juridicoLeyes a:hover{
            text-decoration:underline;
        }
        .juridicoReglamentos{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoReglamentos h3{
            font-size:14px;
            font-weight: bold;
        }
        .juridicoReglamentos ul{
            padding:0;
            margin-left:10px;
        }
        .juridicoReglamentos li{
            font-size:10px;
            text-transform:uppercase;
        }
        .juridicoReglamentos a{
            color:black;
        }
        .juridicoReglamento a:hover{
            text-decoration:underline;
        }
    }

    @media only screen and (min-width:1025px){
         .moduloJuridico{
        width:100%;
        }
        .contenedorJuridico{
            width:100%; 
            height:150px;
            background-color: #541279;
        }
        .juridicoTitulo{
            width:100%;
            height:150px;
            display:flex; align-items: center; justify-content: center;
        }
        .juridicoTitulo h1{
            font-family: 'Montserrat', sans-serif; font-size:24px; font-weight: bold;
            color:#fff;
            text-transform:uppercase;
        }
        .contenedorMarcoJuridico{
            width:100%;
        }
        .marcoJuridicoTitulo{
            width:60%;
            margin:30px auto;
        }
        .marcoJuridicoTitulo h1{
            font-family:'Montserrat',sans-serif;
            font-size:24px; font-weight: bold;
        }
        .marcoJuridicoContenido{
            width:60%;
            margin:10px auto;
        }
        .juridicoAcuerdos{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoAcuerdos h3{
            font-size: 20px;
            font-weight: bold;
        }
        .juridicoAcuerdos ul{
            padding:0;
            margin-left:20px;
        }
        .juridicoAcuerdos a{
            color: black;
        }
        .juridicoAcuerdos a:hover{
            text-decoration:underline;
        }
        .juridicoAcuerdos li {
            font-size:14px;
            text-transform: uppercase;
        }
        .juridicoLeyes{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoLeyes h3{
            font-size:24px;
            font-weight: bold;
        }
        .juridicoLeyes ul{
            padding:0;
            margin-left:20px;
        }
        .juridicoLeyes li{
            font-size:14px;
            text-transform:uppercase;
        }
        .juridicoLeyes a{
            color: black;
        }
        .juridicoLeyes a:hover{
            text-decoration:underline;
        }
        .juridicoReglamentos{
            width:100%;
            font-family:'Montserrat', sans-serif;
        }
        .juridicoReglamentos h3{
            font-size:24px;
            font-weight: bold;
        }
        .juridicoReglamentos ul{
            padding:0;
            margin-left:20px;
        }
        .juridicoReglamentos li{
            font-size:14px;
            text-transform:uppercase;
        }
        .juridicoReglamentos a{
            color:black;
        }
        .juridicoReglamento a:hover{
            text-decoration:underline;
        }
    }
    
</style>