<template>
  <div class="contenedor-consulta">
    <div class="tablero-consulta">
      <h3>Tablero de Consulta</h3>
    </div>
  <!-- Contenedor princial -->
    <div class="contenedor-datos">

      <!-- Contenedor de los filtros -->
      <div class="contenedor-filtro">
        <div class="cont-filtros-titulo">
          <h3>Filtros</h3>
        </div>
        <div class="btn-filtro">
          <p @click="realizarFiltro">Reestablecer consulta</p>
        </div>

        <!-- Filtros -->
        <div class="overflow-filtros">
            <div class="filtro-box">
                <Filtro titulo="Periodo" :data="dataPeriodo" :checkChange="checkChange" />
            </div>
            <div class="filtro-box">
                <Filtro titulo="Municipio" :data="dataMunicipios" :deshabilitar="hideMunicipio" :checkChange="checkChange" />
            </div>
            <div class="filtro-box">
              <Filtro titulo="Rango de Edad" :data="dataEdad"  :checkChange="checkChange"/>
            </div>
            <div class="filtro-box">
              <Filtro titulo="Estado Civil" :data="dataCivil"  :checkChange="checkChange"/>
            </div>
            <div class="filtro-box">
              <Filtro titulo="Nivel de Estudios" :data="dataEstudios"  :checkChange="checkChange" />
            </div>
            <div class="filtro-box">
              <Filtro titulo="Ocupación" :data="dataOcupacion"  :checkChange="checkChange" />
            </div>
        </div>
        

      </div>

      <!-- Contenedor de los datos -->
      <div class="contenedor-data">
        <div class="datos " :class="activoDatos" @click="showDatos">
          <h3>Datos</h3>
        </div>
        <div class="mapa" :class="activoMapa"  @click="showMapa">
          <h3>Mapa</h3>
        </div>
        <div class="grafica" :class="activoGrafica" @click="showGrafica">
          <h3>Gráfica</h3>
        </div>
        <div class="show-data">
          <div 
          class="cont-data" v-if="toggleDatos">
            <table class="data-table" v-if="true">
              <tr>
                <th>Periodo</th>
                <th>Municipio</th>
                <th>Rango Edad</th>
                <th>Estado Civil</th>
                <th>Nivel Estudios</th>
                <th>Ocupación</th>
                <th>AVG</th>
              </tr>
             
              <tr  v-for="(item, index) in data" :key="index">
                <td>{{item.periodo}}</td>
                <td>{{item.municipio}}</td>
                <td>{{item.edad}}</td>
                <td>{{item.civil}}</td>
                <td>{{item.estudios}}</td>
                <td>{{item.ocupacion}}</td>
                <td>{{item.avg}}</td>
              </tr>
            </table>
          </div>
          <div 
            class="cont-data" v-if="toggleMapa">
            <div class="contenedor-mapa">

              <section class="dobleview-mapa-data">
                <Mapa name="mapa" :hover="hover" :outHover="outHover"/>
                <div class="single-data">
                  <h3>{{textoDatos}}</h3>
                  <p>{{newRegistros}}</p>
                </div>
              </section>
              
            </div>
          </div>
          <div class="cont-data" v-if="toggleGrafica">
            <div class="contenedor-grafica">
              <Grafica :dataGrafica="dataGrafica"/>
            </div>
          </div>
        </div>
        <div class="footer-data">
          <p v-if="datoText">{{textoDatos}}  {{totalData}}</p>
          <!-- <p v-if="municipioText">{{textoDatos}} {{ totalData }}</p> -->
          <span style="margin-left:30px"><a href="https://datafeminicidiosmor.org/datas.xlsx">Dascarga base de datos</a></span>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import Filtro from '../components/Filtro.vue'

import Mapa from '../components/Mapa.vue'
import Grafica from '../components/Grafica.vue'

import { ref } from 'vue';
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    Mapa,
    Grafica,
    Filtro
  },
  setup(){

    const municipioText = ref(false);
    const datoText = ref(true);

    const store = useStore();
    const data = computed(() => store.state.dataFem.sort((a,b)=> b-a));
    const totalData = computed(()=> store.state.totalRegistros);

    let newRegistros = ref('');

    let municipio = ref('');
    
    let hover = (nombre) => {
      showFooter.value = true;
      municipio.value = nombre;
      textoDatos.value = `${municipio.value}`;
      
      newRegistros.value = filtrarMunicipio(nombre) + ` de ${totalData.value} registros` 
      

    }

    const filtrarMunicipio = idMunicipio => {
      let newData = data.value;
      let filtro = newData.filter( nombre => nombre.municipio === idMunicipio );

      return filtro.length
    }



    let outHover = () => {
        textoDatos.value = 'Selecciona un municipio'
        newRegistros.value = ''
    }

    let toggleDatos = ref(null);
    let toggleMapa = ref(null);
    let toggleGrafica = ref(null);
    let activoDatos = ref(null);
    let activoMapa = ref(null);
    let activoGrafica = ref(null);
    let hideMunicipio = ref(null);
    let showFooter = ref(true);
    let dataPeriodo = ref([]);
    let dataMunicipios = ref([]);
    let dataEdad = ref([]);
    let dataCivil = ref([]);
    let dataEstudios = ref([]);
    let dataOcupacion = ref([]);

    let textoDatos = ref('');
    let datoMunicipio = ref('')

    onMounted( () => {
        store.dispatch("getFemData")
        toggleDatos.value = true;
        activoDatos.value = 'activo';
        showFooter.value = true;
        textoDatos.value = `Total Registros: `;
        

        mkPeriodo();
        mkMunicipios();
        mkEdad();
        mkCivil();
        mkEstudios();
        mkOcupacion();
        showDatos();
    })

    const showDatos = () => {
      hideMunicipio.value = false;
      toggleDatos.value = true;
      activoDatos.value = 'activo';

      toggleMapa.value = false;
      activoMapa.value = '';

      toggleGrafica.value = false;
      activoGrafica.value = '';

      textoDatos.value = 'Total registros: '
      municipioText.value = false;
      datoText.value = true;    

    };

    const showMapa = () => {
      toggleMapa.value = true;
      activoMapa.value = 'activo';

      toggleDatos.value = false;
      activoDatos.value = '';

      toggleGrafica.value = false;
      activoGrafica.value = '';


      hideMunicipio.value = true;

      textoDatos.value = 'Total registros '
      datoMunicipio.value = '-'

      municipioText.value = true;
      datoText.value = false;    



    };

    const dataGrafica = ref([]);

    const showGrafica  = () => {
      toggleGrafica.value = true;
      activoGrafica.value = 'activo';

      toggleDatos.value = false;
      activoDatos.value = '';

      toggleMapa.value = false;
      activoMapa.value = '';

      fillGrafica();

    };

    const mkPeriodo = () => {
      let fecha = new Date();
      let year = fecha.getFullYear();

      let yearPeriodo = year;

      dataPeriodo.value.push({
        nombre: 'Todos los periodos',
        value:''
      });

      for(let i = yearPeriodo; i > 1999; i--){
                dataPeriodo.value.push({
                    nombre: `${i}`,
                    value: i
                })
            }
    };

    const mkMunicipios = () => {
      dataMunicipios.value.push(
          { nombre:"Todos los municipios", value:"" },
          { nombre: 'Amacuzac', value: 'Amacuzac' },
          { nombre: 'Atlatlahucan', value: 'Atlatlahucan' },
          { nombre: 'Axochiapan', value: 'Axochiapan' },
          { nombre: 'Ayala', value: 'Ayala' },
          { nombre: 'Coatlán del Río', value: 'Coatlán del Río' },
          { nombre: 'Cuautla', value: 'Cuautla' },
          { nombre: 'Cuernavaca', value: 'Cuernavaca', },
          { nombre: 'Emiliano Zapata', value: 'Emiliano Zapata' },
          { nombre: 'Huitzilac', value: 'Huitzilac' },
          { nombre: 'Jantetelco', value: 'Jantetelco' },
          { nombre: 'Jiutepec', value: 'Jiutepec' },
          { nombre: 'Jojutla', value: 'Jojutla' },
          { nombre: 'Jonacatepec', value: 'Jonacatepec' },
          { nombre: 'Mazatepec', value: 'Mazatepec' },
          { nombre: 'Miacatlán', value: 'Miacatlán' },
          { nombre: 'Ocuituco', value: 'Ocuituco' },
          { nombre: 'Puente de Ixtla', value: 'Puente de Ixtla' },
          { nombre: 'Temixco', value: 'Temixco' },
          { nombre: 'Tepalcingo', value: 'Tepalcingo' },
          { nombre: 'Tepoztlán', value: 'Tepoztlán' },
          { nombre: 'Tetecala', value: 'Tetecala' },
          { nombre: 'Tetela del Volcán', value: 'Tetela del Volcán' },
          { nombre: 'Tlalnepantla', value: 'Tlalnepantla' },
          { nombre: 'Tlaltizapán', value: 'Tlaltizapán' },
          { nombre: 'Tlaquiltenango', value: 'Tlaquiltenango' },
          { nombre: 'Tlayacapan', value: 'Tlayacapan' },
          { nombre: 'Totolapan', value: 'Totolapan' },
          { nombre: 'Xochitepec', value: 'Xochitepec' },
          { nombre: 'Yautepec', value: 'Yautepec' },
          { nombre: 'Yecapixtla', value: 'Yecapixtla' },
          { nombre: 'Zacatepec', value: 'Zacatepec' },
          { nombre: 'Zacualpan', value: 'Zacualpan' },
          { nombre: 'Temoac', value: 'Temoac' },


        )
    };

    const mkEdad = () => {
      dataEdad.value.push(
        { nombre: 'Todos los rangos', value: '' },
        { nombre: 'S/D', value: 'S/D' },
        { nombre: '0 a 5', value: '0 a 5' },
        { nombre: '6 a 10', value: '6 a 10' },
        { nombre: '11 a 15', value: '11 a 15' },
        { nombre: '16 a 20', value: '16 a 20' },
        { nombre: '21 a 25', value: '21 a 25' },
        { nombre: '26 a 30', value: '26 a 30' },
        { nombre: '31 a 35', value: '31 a 35' },
        { nombre: '36 a 40', value: '36 a 40' },
        { nombre: '41 a 45', value: '41 a 45' },
        { nombre: '46 a 50', value: '46 a 50' },
        { nombre: '51 a 55', value: '51 a 55' },
        { nombre: '56 a 60', value: '56 a 60' },
        { nombre: '61 a 65', value: '61 a 65' },
        { nombre: '66 a 70', value: '66 a 70' },
        { nombre: '71 y más', value: '70 y más' }
      )
    }

    const mkCivil = () => {
      dataCivil.value.push(
        {nombre:'Todos los estados civiles', value:''},
        {nombre:'S/D', value:'S/D' },
        {nombre:'Soltera', value:'Soltera' },
        {nombre:'Casada', value:'Casada' },
        {nombre:'Divorciada', value:'Divorciada' },
        {nombre:'Union Libre', value:'Union Libre' },
        {nombre:'Viuda', value:'Viuda'}
      )
    }

    const mkEstudios = () => {
      dataEstudios.value.push(
        {nombre: 'Todos los niveles', value:'' },
        {nombre: 'S/D', value:'S/D'},
        {nombre: 'Sin escolaridad', value:'Sin escolaridad'},
        {nombre: 'Ninguno', value:'Ninguno'},
        {nombre: 'Alfabeta', value:'Alfabeta'},
        {nombre: 'Primaria', value:'Primaria'},
        {nombre: 'Secundaria', value:'Secundaria'},
        {nombre: 'Bachillerato', value:'Bachillerato'},
        {nombre: 'Preparatoria', value:'Preparatoria'},
        {nombre: 'Carrera Técnica', value:'Carrera Técnica'},
        {nombre: 'Profesional', value:'Profesional'},
        {nombre: 'Postgrado', value:'Postgrado'}
      )
    }

    const mkOcupacion = () => {
      dataOcupacion.value.push(
        { nombre: 'Todas las ocupaciones', value: ''},
        { nombre: 'S/D', value: 'S/D' },
        { nombre: 'Ninguna', value: 'Ninguna'},
        { nombre: 'Campo', value: 'Campo' },
        { nombre: 'Comerciante', value: 'Comerciante' },
        { nombre: 'Desempleada', value: 'Desempleada' },
        { nombre: 'Empleada', value: 'Empleada' },
        { nombre: 'Empresaria', value: 'Empresaria' },
        { nombre: 'Enfermera', value: 'Enfermera' },
        { nombre: 'Estudiante', value: 'Estudiante' },
        { nombre: 'Hogar', value: 'Hogar' },
        { nombre: 'Jubilada', value: 'Jubilada' },
        { nombre: 'Profesora', value: 'Profesora'},
        { nombre: 'Sexoservidora', value: 'Sexoservidora'}
      )
    }

    const fillGrafica = ( item  ) => {
     
        const municipios = ['Amacuzac','Atlatlahucan','Axochiapan','Ayala','Coatlan del Rio','Cuautla','Cuernavaca','Emiliano Zapata','Huitzilac','Jantetelco','Jiutepec','Jojutla','Jonacatepec','Mazatepec','Miacatlan','Ocuituco','Puente de Ixtla','Temixco','Tepalcingo','Tepoztlan','Tetecala','Tetela del Volcan','Tlalnepantla','Tlaltizapan','Tlaquiltenango','Tlayacapan','Totolapan','Xochitepec','Yautepec','Yecapixtla','Zacatepec','Zacualpan','Temoac']

        const municipiosDatos = computed(() => store.state.dataFem);
          for (let index = 0; index < municipios.length; index++) {

            let filter = municipiosDatos.value.filter( dato => dato.municipio === municipios[index]);    
            dataGrafica.value.push([
                municipios[index],
                filter.length
            ])
          }
        }
      
    let checkChange = e => {
           store.dispatch("getFemData",{
             value: e.target.value
           })
    }

    const realizarFiltro = () => {
       store.dispatch("getFemData",{
             value: ""
           })
    }


    return{
      toggleDatos,
      toggleMapa,
      toggleGrafica,
      activoDatos,
      activoMapa,
      activoGrafica,
      showDatos,
      showMapa,
      showGrafica,

      dataPeriodo,
      dataMunicipios,
      dataEdad,
      dataCivil,
      dataEstudios,
      dataOcupacion,

      store,
      data,
      totalData,
      textoDatos,
      datoMunicipio,

      checkChange,
      hideMunicipio,
      hover,
      outHover,
      municipio,
      showFooter,

      municipioText,
      datoText,

      dataGrafica,

      newRegistros,
      realizarFiltro
      
    }
  }
  
}
</script>


<style scoped>
  .contenedor-consulta{
    width: 100%;
    height:600px;
    background-color:#fff;
  }
  .tablero-consulta{
    width:100%;
    height:70px;
    background-color: #525056;
    display:flex; align-items:center;
  }
  .tablero-consulta h3{
    width:70%;
    margin:0 auto;
    color: #fff;
  }

  .contenedor-datos{
    width:100%;
    height:700px;
    margin:0 auto;
    background-color:#f2f2f2;
    display:flex; flex-wrap:wrap;
  }

  @media only screen and ( min-width:1500px){
    .contenedor-datos{
      width:90%;
    }
  }

  .contenedor-filtro{
    width:20%;
    height:580px;
    background-color:#fff;
    margin:20px auto;
    padding:10px 12px;
  }
  .cont-filtros-titulo{
    width:70%;
    margin:0 auto;
    text-align:center;
  }
  .btn-filtro{
    width:70%;
    margin:0 auto;
    text-align:center;
  }
  .btn-filtro button{
    width:90%;
    margin:10px auto;
    background-color: #339DFF;
    color:#fff;
    padding:10px;
    border:none;
    cursor:pointer;
  }
  .btn-filtro p{
    font-size:12px;
    color:#339DFF;
    cursor:pointer;
  }
  .btn-filtro p:hover{
    text-decoration:underline;
  }
  .overflow-filtros{
    width:100%;
    height:450px;
    overflow-y:auto;
    scroll-behavior: smooth;

  }
  .overflow-filtros::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .contenedor-data{
    width:75%;
    height:580px;
    background-color:#fff;
    margin:20px auto;
    display:flex; flex-wrap:wrap; 
  }
  .datos{
    width:33%;
    height:50px;
    text-align:center;
    cursor:pointer;

  }
  .mapa{
    width:33%;
    height:50px;
    text-align:center;
    cursor:pointer;

  }
  .grafica{
    width:30%;
    width:33%;
    height:50px;
    text-align:center;
    cursor:pointer;
  }
  .activo{
    background-color: #f2f2f2;
  }
  .show-data{
    width:95%;
    height:450px;
    margin:0 auto;
    overflow-y:auto;
  }
  .footer-data{
    width:95%;
    height:50px;
    background-color:#f1f1f1;
    margin:0 auto;
    display:flex; align-items:center; justify-content:center;
  }

  /* Contenedor show Data  */

  .cont-data{
    width:98%;
    height:auto;
    margin:10px auto;

  }

  /* Estilos Tablas */
  .data-table{
    width:100%;
    border-collapse: collapse;

  }
  .data-table td, .data-table th{
    border: 1px solid #ddd;
    padding:8px;
  }
  .data-table tr:nth-child(even){background-color: #fff;}
  .data-table tr:hover{background-color: #ddd;}

  .data-table th{
    padding:12px;
    text-align: left;
    background-color: #8e44ad;
    color: #fff;
    text-align:center;
    font-size:14px;
  }
  .data-table td{
    font-size:11px;
    text-align:center;
  }

/* Contenedor mapa  */
.contenedor-mapa{
  width:95%;
  margin:0 auto;
  background-color:#fff;
}
.contenedor-grafica{
  width:95%;
  height:450px;
  display:flex; align-items:center; justify-content:center;
}

.dobleview-mapa-data {
  display: flex;  justify-content:center;
  width:100%;
}
.single-data {
  width:30%;
}
  
</style>
