<template>
  <div class="componente-grafica">
      <column-chart :data="dataGrafica" :download="true"></column-chart>
  </div>
</template>

<script>
export default {
    props:{
        dataGrafica: Array
    }

}
</script>

<style scoped>
    .componente-grafica{
        width:100%;
        height:100%;
        display:flex; align-items:center; justify-content:center;
    }

</style>