<template>
    <div class="ContenedorAtencion">
        <div class="atencionTexto">
            <h1>Atención Ciudadana</h1>
        </div>

        <div class="instituciones">
            <div class="fiscalia">
                <div class="fiscaliaInformacion">
                    <div class="logoFiscalia">
                        <div class="contenedorLogo">
                            <div class="logoFGE_contacto">
                                <img src="../assets/img/logo-fge.png" alt="Logo fiscalia">
                            </div>
                        </div>
                    </div>
                    <div class="textoInformacion">
                        <h1>Fiscalía General del Estado de Morelos</h1>
                        <p><b>En La Fiscalía General del Estado de Morelos puedes denunciar si vives cualquiera de los tipos y modalidades de violencia</b></p>
                        <p>Av. Emiliano Zapata #803, Col. Buena Vista, 62130, Cuernavaca, Morelos</p>
                        <p>Ard. Blvd. Apatlaco 165, Campo Del Rayo, 62590, Temixco, Morelos</p>
                        <p>Telefóno: (777) 3 29 15 00</p>
                        
                    </div>
                </div>
            </div>
            <div class="imm">
                <div class="institutoInformacion">
                    <div class="logoIMM">
                        <div class="immcontenedorLogo">
                            <div class="logoIMM_contacto">
                                <img src="../assets/img/logoNuevo.png" alt="Logo IMM">
                            </div>
                        </div>
                    </div>
                    <div class="immInformacion">
                        <h1>Instituto de la Mujer para el Estado de Morelos</h1>
                        <p><b>El Instituto de la Mujer para el Estado de Morelos brinda servicios gratuitos de atención psicológica y asesoría legal para mujeres en situación de violencia. También atiende a sus hijas e hijos.</b></p>
                        <p>Abasolo No. 6. Esquina Av. Morelos Col. Centro, 62000</p>
                        <p>Telefóno: (777) 173 00 66</p>
                        <p>Línea de atención 24 horas: <br> (777) 534 25 89 / (777) 538 55 99 <br> (777) 538 66 37 / (777) 538 66 09</p>
                        <a href="https://www.immorelos.org.mx/" target="_blank"></a>
                    </div>
                </div>
            </div>
            <div class="centroJusticia">
                <div class="informacionCentroJusticia">
                    <div class="logoCentroJusticia">
                        <div class="logoContenedor">
                            <div class="contenedorCentroJusticia">
                                <img src="../assets/img/logocjmpng.png" alt="Centro de Justicia para la Mujer">
                            </div>
                        </div>
                    </div>
                    <div class="centroInformacion">
                        <h1>Centro de Justicia para las Mujeres</h1>
                        <p><b>Brinda orientación, atención y protección a mujeres en situación de violencia, hijas e hijos. Asesoría legal, atención psicológica, ludoteca, trabajo social y Casa de transición.</b></p>
                        <p>Cuernavaca <br>Av. Morelos Sur No. 70 Col. Chipitlán</p>
                        <p>(777) 318 8753 - (777) 310 24 06 <br>Horario: 24 horas</p>
                        <p>Yautepec <br>Manzana 26 de la lotificación de la Col. Corral Grande del Predio "Loma de Morillo"<br>Carretera Yautepec - Tlayacapan Col. Oaxtepec</p>
                        <p>(735) 356 16 47 <br>Horario: 24 horas</p>
                    </div>
                </div>
            </div>
            <div class="catContenedor">
                <div class="catInformacion">
                    <div class="catLogoComponente">
                        <div class="catLogoContenedor">
                            <div class="catLogoContenedorImg">
                                <img src="../assets/img/cat.jpg" alt="Centro de Atención Telefónica">
                            </div>
                        </div>
                    </div>
                    <div class="catInformacionComponente">
                        <h1>Centro de Atención Telefónica</h1>
                        <p><b>Atención y Canalización, Fiscalía General del Estado de Morelos</b></p>
                        <p>Telefóno: 800-911-7777</p>
                        <p>Atención en todo el estado <br>Lunes a Viernes: 8:00 a 17:00 horas</p>
                    </div>
                </div>
            </div>
            <div class="contenedor911">
                <div class="informacion911">
                    <div class="logo911Componente">
                        <div class="logo911Contenedor">
                            <div class="logo911ContenedorImg">
                                <img src="../assets/img/numero911.png" alt="911">
                            </div>
                        </div>
                    </div>
                    <div class="informacion911Componente">
                        <h1>Servicio de Atención de Llamadas de Emergencia</h1>
                        <p>Mecanismo de auxilio a la población que requiera apoyo de las Instituciones y Corporaciones de Seguirdad Pública</p>
                        <h1>Marcando 911</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
   @media only screen and (max-width:600px){
    .contenedorAtencion{
    width:100%;
    }
    .atencionTexto{
        width:100%;
        height:120px;
        background-color:#541279;
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .atencionTexto h1{
        color:#fff;
        font-family:'Montserrat', sans-serif;
        text-transform:uppercase;
        font-weight: bold;
        font-size: 14px;;
    }
    .instituciones{
        width:100%;
        display:flex;
        flex-wrap: wrap;
        margin-top:40px;
    }
        
        .fiscalia{
            width:100%;
        }
        .fiscaliaMapa{
            width:100%;
            height:120px;
            border:1px solid black;
        }
        .logoFiscalia{
            width:120px;
            height:120px;
            margin:10px auto;
        }
        .contenedorLogo{
            width:100%;
            margin:0 auto;
        }
        .logoFGE_contacto {
            width:100%;
        }
        .logoFGE_contacto img{
            width:100%;
        }
        .textoInformacion{
            width:80%;
            margin:10px auto;
            font-family: 'Montserrat', sans-serif;
        }
        .textoInformacion h1{
            font-size:24px;
            text-align:center;
        }
        .textoInformacion p{
            font-size:14px;
            text-align:center;
        }
        .logoIMM{
            width:250px;
            height:100px;
            margin:10px auto;
            display:flex; align-items:center; justify-content:center;
        }
         .immcontenedorLogo{
            width:100%;
            display:flex;
            justify-content:center;
            align-items: center;
        }
        .immcontenedorLogo img{
            width:100%;
            text-align:center;
        }
        .immInformacion{
            width:80%;
            margin:10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .immInformacion{
            width:80%;
            margin:10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .immInformacion h1{
            font-size:24px;
            text-align:center;
        }
        .immInformacion p{
            font-size:14px;
            text-align:center;
        }
        .centroJusticia{
            width:100%;
            margin:10px auto;
        }
        .informacionCentroJusticia{
            width:100%;
            display:flex;
            flex-wrap: wrap;
        }
        .logoCentroJusticia{
            width:100%;
        }
        .logoCentenedor{
            width:100%;
        }
        .contenedorCentroJusticia{
            width:100%;
            margin:0 auto;
            display:flex; align-items:center; justify-content:center;
        }
        .contenedorCentroJusticia img{
            width:70%;
        }
        .centroInformacion{
            width:80%;
            margin:20px auto;
            font-family:'Montserrat',sans-serif;
            display: flex; align-items:flex-start; justify-content:center; flex-direction:column;
            text-align:center;
        }
        .centroInformacion h1{
            font-size:24px;
            font-weight: bold;
            text-align:center;
        }
        .centroInformacion p{
            font-size: 14px;
            text-align:center;
        }

        .catContenedor{
            width:100%;
        }
        .catInformacion{
            width:100%;
            display:flex; align-items:center; justify-content:center; flex-wrap: wrap;
        }
        .catLogoComponente{
            width:100%;
        }
        .catLogoContenedor{
            width:100%;
        }
        .catLogoContenedorImg{
            width:60%;
            margin:0 auto;
        }
        .catLogoContenedorImg img{
            width:100%;
        }
        .catInformacionComponente{
            width:80%;
            margin: 10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .catInformacionComponente h1{
            font-size:24px;
            font-weight: bold;
            text-align:center;
        }
        .catInformacionComponente p{
            font-size:14px;
            text-align:center;
        }

        .contenedor911{
            width:100%;
        }
        .informacion911{
            width:100%;
            height:400px;
            display:flex; align-items:center; justify-content:center; flex-wrap: wrap;
        }
        .logo911Componente{
            width:100%;
        }
        .logo911Contenedor{
            width:100%;
        }
        .logo911ContenedorImg{
            width:60%;
            margin: 0 auto;
            text-align:center;
        }
        .logo911ContenedorImg img{
            width:50%;
            
        }
        .informacion911Componente{
            width:80%;
            margin:10px auto;
            font-family: 'Montserrat', sans-serif;
        }
        .informacion911Componente h1{
            font-size:24px;
            font-weight: bold;
            text-align:center;
        }
        .informacion911Componente p{
            font-size:14px;
            text-align:center;
        }

    }
    @media only screen and (min-width:601px) and (max-width:1024px){
    .contenedorAtencion{
        width:100%;
    }
    .atencionTexto{
        width:100%;
        height:120px;
        background-color:#541279;
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .atencionTexto h1{
        color:#fff;
        font-family:'Montserrat', sans-serif;
        text-transform:uppercase;
        font-weight: bold;
        font-size: 24px;;
    }
    .instituciones{
        width:100%;
        display:flex;
        flex-wrap: wrap;
    }
       
        .fiscalia{
            width:100%;
            margin-top:30px;
        }
        .logoFiscalia{
            width:200px;
            height:200px;
            margin:0 auto;
        }
        .contenedorLogo{
            width:80%;
            margin:0 auto;
        }
        .contenedorLogo img{
            width:90%;
        }
        .textoInformacion{
            width:80%;
            margin:20px auto;
            font-family: 'Montserrat', sans-serif;
        }
        .textoInformacion h1{
            font-size:24px;
            text-align:center;
        }
        .textoInformacion p{
            font-size:14px;
            text-align:center;
        }
        .imm{
            width:100%;
        }
        
        .logoIMM{
            width:300px;
            height:100px;
            margin:10px auto;
        }
         .immcontenedorLogo{
            width:100%;
            display:flex;
            justify-content:center;
            align-items: center;
        }
        .immcontenedorLogo img{
            width:100%;
            text-align:center;
        }
        .immInformacion{
            width:80%;
            margin:10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .immInformacion{
            width:80%;
            margin:10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .immInformacion h1{
            font-size:24px;
            text-align:center;
        }
        .immInformacion p{
            font-size:14px;
            text-align:center;
        }
         .centroJusticia{
            width:100%;
            margin:10px auto;
        }
        .informacionCentroJusticia{
            width:100%;
            display:flex;
            flex-wrap: wrap;
        }
        .logoCentroJusticia{
            width:100%;
        }
        .logoCentenedor{
            width:100%;
        }
        .contenedorCentroJusticia{
            width:100%;
            margin:0 auto;
            display:flex; align-items:center; justify-content:center;
        }
        .contenedorCentroJusticia img{
            width:70%;
        }
        .centroInformacion{
            width:80%;
            margin:20px auto;
            font-family:'Montserrat',sans-serif;
            display: flex; align-items:flex-start; justify-content:center; flex-direction:column;
            text-align:center;
        }
        .centroInformacion h1{
            font-size:24px;
            font-weight: bold;
            text-align:center;
        }
        .centroInformacion p{
            font-size: 14px;
            text-align:center;
        }

        .catContenedor{
            width:100%;
        }
        .catInformacion{
            width:100%;
            display:flex; align-items:center; justify-content:center; flex-wrap: wrap;
        }
        .catLogoComponente{
            width:100%;
        }
        .catLogoContenedor{
            width:100%;
        }
        .catLogoContenedorImg{
            width:60%;
            margin:0 auto;
        }
        .catLogoContenedorImg img{
            width:100%;
        }
        .catInformacionComponente{
            width:80%;
            margin: 10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .catInformacionComponente h1{
            font-size:24px;
            font-weight: bold;
            text-align:center;
        }
        .catInformacionComponente p{
            font-size:14px;
            text-align:center;
        }

        .contenedor911{
            width:100%;
        }
        .informacion911{
            width:100%;
            height:400px;
            display:flex; align-items:center; justify-content:center; flex-wrap: wrap;
        }
        .logo911Componente{
            width:100%;
        }
        .logo911Contenedor{
            width:100%;
        }
        .logo911ContenedorImg{
            width:60%;
            margin: 0 auto;
            text-align:center;
        }
        .logo911ContenedorImg img{
            width:50%;
            
        }
        .informacion911Componente{
            width:80%;
            margin:10px auto;
            font-family: 'Montserrat', sans-serif;
        }
        .informacion911Componente h1{
            font-size:24px;
            font-weight: bold;
            text-align:center;
        }
        .informacion911Componente p{
            font-size:14px;
            text-align:center;
        }
    }
     @media only screen and (min-width:1025px){
    .contenedorAtencion{
    width:100%;
    }
    .atencionTexto{
        width:100%;
        height:150px;
        background-color:#541279;
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .atencionTexto h1{
        color:#fff;
        font-family:'Montserrat', sans-serif;
        text-transform:uppercase;
        font-weight: bold;
        font-size: 24px;;
    }
    .instituciones{
        width:100%;
        display:flex;
        flex-wrap: wrap;
    }
        .fiscalia{
            width:100%;
            
        }
        .fiscaliaInformacion{
            display:flex;
            width:100%;
            
        }
        .logoFiscalia{
            width:50%;
            height:400px;
        }
        .contenedorLogo{
            width:100%;
            height:100%;
        }
        .logoFGE_contacto{
            width:70%;
            height:400px;
            margin:0 auto;
            display:flex; align-items:center; justify-content:center;
        }
        .logoFGE_contacto img{
            width:90%;
        }
       
        .textoInformacion{
            width:45%;
            margin:10px auto;
            font-family: 'Montserrat', sans-serif;
            display:flex;
            flex-direction:column;
            align-items:flex-start;
            justify-content:center;
        }
        .textoInformacion h1{
            font-size:30px;
            font-weight: bold;
        }
        .textoInformacion p{
            font-size:16px;
        }
       
        .imm{
            width:100%;
        }

        .institutoInformacion{
            display:flex;
            width:100%;
        }
        .logoIMM{
            width:50%;
            height:400px;
        }
        .immcontenedorLogo{
            width:100%;
            height:400px;
        }
        .logoIMM_contacto{
            width:70%;
            height:400px;
            margin:0 auto;
            display:flex; align-items:center; justify-content:center;
        }
        .logoIMM_contacto img{
            width:90%;
        }
        
        .immInformacion{
            width:45%;
            margin:10px auto;
            font-family:'Montserrat', sans-serif;
            display:flex;
            flex-direction:column;
            align-items:flex-start;
            justify-content:center;
        }
        .immInformacion h1{
            font-size:30px;
            font-weight: bold;
        }
        .immInformacion p{
            font-size:16px;
        }
        .centroJusticia{
            width:100%;
            height:500px;
            margin:10px auto;
        }
        .informacionCentroJusticia{
            width:100%;
            display:flex;
            height:500px;
        }
        .logoCentroJusticia{
            width:50%;
        }
        .logoCentenedor{
            width:100%;
            height:500px;
        }
        .contenedorCentroJusticia{
            width:70%;
            height:500px;
            margin:0 auto;
            display:flex; align-items:center; justify-content:center;
        }
        .contenedorCentroJusticia img{
            width:95%;
        }
        .centroInformacion{
            width:45%;
            margin:10px auto;
            font-family:'Montserrat',sans-serif;
            display: flex; align-items:flex-start; justify-content:center; flex-direction:column;
        }
        .centroInformacion h1{
            font-size:30px;
            font-weight: bold;
        }

        .catContenedor{
            width:100%;
        }
        .catInformacion{
            width:100%;
            height:450px;
            display:flex; align-items:center; justify-content:center;
        }
        .catLogoComponente{
            width:50%;
        }
        .catLogoContenedor{
            width:100%;
        }
        .catLogoContenedorImg{
            width:60%;
            margin:0 auto;
        }
        .catLogoContenedorImg img{
            width:100%;
        }
        .catInformacionComponente{
            width:45%;
            margin: 10px auto;
            font-family:'Montserrat', sans-serif;
        }
        .catInformacionComponente h1{
            font-size:30px;
            font-weight: bold;
        }
        .contenedor911{
            width:100%;
        }
        .informacion911{
            width:100%;
            height:400px;
            display:flex; align-items:center; justify-content:center;
        }
        .logo911Componente{
            width:50%;
        }
        .logo911Contenedor{
            width:100%;
        }
        .logo911ContenedorImg{
            width:60%;
            margin: 0 auto;
            text-align:center;
        }
        .logo911ContenedorImg img{
            width:50%;
            
        }
        .informacion911Componente{
            width:45%;
            margin:10px auto;
            font-family: 'Montserrat', sans-serif;
        }
        .informacion911Componente h1{
            font-size:30px;
            font-weight: bold;
        }
    }
</style>